import React, { useState, Fragment } from "react"
import { Form } from "react-final-form"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { useToasts } from "react-toast-notifications"

import Seo from "../components/Seo"
import PageHeader from "../components/PageHeader"
import FlexibleContent from "../components/FlexibleContent"
import Divider from "../components/Divider"
import Spacer from "../components/Spacer"
import Input from "../components/Input"
import Button from "../components/Button"
import { breakpoints } from "../assets/globalStyles"
import Checkbox from "../components/Checkbox"

const StyledJobLink = styled(Link)`
  color: #f03a85 !important;
  display: block;
  &:hover {
    font-weight: 500;
  }
`

const StyledUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media screen and (min-width: ${breakpoints.md}px) {
    align-items: center;
    flex-direction: row;
  }
  input {
    display: none;
  }
  label {
    color: #1c1c26 !important;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    display: block;
  }
  .file-name {
    color: #66768e;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
  }
  .upload-button {
    border: 1px solid #ced4db;
    border-radius: 4px;
    color: #f03a85 !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    padding: 16px 24px;
    display: inline-block;
    width: 100%;
    transition: all 150ms ease-in-out;
    cursor: pointer;
    @media screen and (min-width: ${breakpoints.md}px) {
      width: auto;
    }
    &:hover {
      background-color: #eef1f4;
    }
  }
`

export const jobVacancies = graphql`
  query($id: String!) {
    wpVacancy(id: { eq: $id }) {
      title
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      pageHeaderFields {
        headerContent
      }
      flexibleGeneralContentFields {
        ...FlexibleContentVacancy
      }
    }
    allWpVacancy(filter: { id: { ne: $id } }) {
      nodes {
        title
        slug
      }
    }
  }
`

export default function JobListing({
  data: {
    wpVacancy: {
      title,
      seo,
      featuredImage,
      pageHeaderFields: { headerContent },
      flexibleGeneralContentFields: { content: pageContent },
    },
    allWpVacancy: { nodes: otherVacancies },
  },
}) {
  const [upload, setUpload] = useState()
  const { addToast } = useToasts()

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} />
      <Spacer size={32} when={{ lg: 48 }} />
      <FlexibleContent content={pageContent} />
      <Spacer size={32} when={{ lg: 48 }} />
      <Form
        initialValues={{ position: title }}
        onSubmit={async (values) => {
          // dont send mail if the honeypot has been filled in
          if (values.sweet) return

          await fetch("https://ddash.uk/mailer/send-mail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${
                typeof window === "undefined"
                  ? ""
                  : window.btoa(`Daffodil:563919d14ce0adceffa46a6b61b23fa2`)
              }`,
            },
            body: JSON.stringify({
              host: "api.eu.mailgun.net",
              from:
                "Daffodil Marketing Website <website@daffodil-marketing.com>",
              to: "vacancies@daffodil-group.co.uk",
              subject: `Daffodil Marketing vacancies submission from ${values.name} for position: ${values.position}`,
              text: `${values.name} has applied for the role of ${
                values.position
              } using the contact form on the website. They included the following message: ${
                values.coverLetter
              } To get in touch with ${values.name}, please call ${
                values.phone
              }, email ${values.email}, or reply to this email. ${
                upload?.attachment
                  ? "They chose to include a CV which has been attached to this email"
                  : ""
              }`,
              replyTo: values.email,
              domain: "daffodil-marketing.com",
              html: `<p>${values.name} has applied for the role of ${
                values.position
              } using the contact form on the website.</p> <p>They included the following message: </br> ${
                values.coverLetter
              }</p><p>To get in touch with ${values.name}, please call ${
                values.phone
              }, email ${values.email}, or reply to this email.</p> <p>${
                upload?.attachment
                  ? "They chose to include a CV which has been attached to this email"
                  : ""
              }</p>`,
              file: upload?.attachment || undefined,
              fileName: upload?.fileExtension
                ? `${values.name} - cv.${upload.fileExtension}`
                : null,
              autoResponse: {
                from: "Daffodil Marketing <website@daffodil-marketing.com>",
                to: values.email,
                replyTo: "vacancies@daffodil-group.co.uk",
                subject: "Thank you for getting in touch!",
                text: `Thank you for applying for the role of ${values.position} at Daffodil Marketing! We will review your application and someone will be in touch shortly. Kind Regards, Daffodil Marketing`,
                html: `<p>Thank you for applying for the role of ${values.position} at Daffodil Marketing! We will review your application and someone will be in touch shortly.</p> <p>Kind Regards, </br>Daffodil Marketing</p>`,
              },
            }),
          }).then(({ status }) => {
            if (status === 200) {
              addToast("Thank you for submitting your application!", {
                appearance: "success",
              })
            } else {
              addToast("Something went wrong, please try again.", {
                appearance: "error",
              })
            }
          })
        }}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit} noValidate className="container">
            <div className="row">
              <div className="col-12">
                <h2>Apply for this position</h2>
                <Spacer size={32} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="name"
                  id="name"
                  type="text"
                  required
                  label="Name"
                  fullwidth
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  name="position"
                  id="position"
                  type="text"
                  required
                  label="Position"
                  fullwidth
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="email"
                  id="email"
                  type="email"
                  required
                  label="Email"
                  fullwidth
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  name="phone"
                  id="phone"
                  type="number"
                  required
                  label="Phone"
                  fullwidth
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  name="coverLetter"
                  id="coverLetter"
                  type="textarea"
                  required
                  label="Cover Letter"
                  fullwidth
                />
                <Input
                  name="sweet"
                  id="sweet"
                  type="text"
                  label="Sweet"
                  fullwidth
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <StyledUpload>
                  <div>
                    <label htmlFor="cv">CV</label>
                    <input
                      type="file"
                      name="cv"
                      id="cv"
                      accept=".docx,.pdf"
                      onChange={(event) => {
                        const reader = new FileReader()
                        reader.readAsDataURL(event.target.files[0])

                        const fileName = event.target.files[0].name

                        reader.addEventListener(
                          "load",
                          (result) => {
                            const file = result.currentTarget.result
                            const res = file.split(",")

                            const base64 = res[1]
                            let fileExtension = res[0].split("/")
                            fileExtension = fileExtension[1].split(";")

                            fileExtension = fileExtension[0]

                            if (
                              fileExtension ===
                              "vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ) {
                              fileExtension = "docx"
                            }

                            setUpload({
                              attachment: base64,
                              fileExtension: fileExtension,
                              fileName,
                            })
                          },
                          false
                        )
                      }}
                      aria-label="input file"
                    />
                    <label htmlFor="cv" className="upload-button">
                      Upload File
                    </label>
                  </div>
                  {upload?.fileName && (
                    <>
                      <Spacer size={0} when={{ md: 24 }} />
                      <div>
                        <Spacer size={24} when={{ md: 40 }} />
                        <p className="file-name">{`File Uploaded: ${upload.fileName}`}</p>
                      </div>
                    </>
                  )}
                </StyledUpload>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Spacer size={24} />
                <Checkbox
                  label="I agree to Daffodil Marketing's Privacy Policy and Terms & Conditions"
                  required
                  name="terms"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  type="submit"
                  disabled={pristine || invalid}
                  colour="primary"
                  variant="contained"
                >
                  Submit Application
                </Button>
              </div>
            </div>
          </form>
        )}
      />

      {!!otherVacancies.length && (
        <>
          <Spacer size={32} when={{ lg: 48 }} />
          <Divider />
          <Spacer size={32} when={{ lg: 48 }} />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Other Openings</h2>
                <Spacer size={32} />
                {otherVacancies.map(({ title, slug }, index) => (
                  <Fragment key={slug}>
                    <StyledJobLink to={`/vacancies/${slug}/`}>
                      {title}
                    </StyledJobLink>
                    {index !== otherVacancies.length - 1 && (
                      <Spacer size={16} />
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
    </>
  )
}
